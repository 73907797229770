<template>
  <div class="form-container">
  <v-row class="md-layout">
    <form novalidate>
      <md-card-content class="search-content">
        <v-row class="user-search-bar">
          <v-col cols="4" xl="4" lg="4">
            <md-field>
              <label for="search-query">Search</label>
              <md-input
                name="search"
                id="search"
                v-model="query"
                v-on:input="filteredFeedbacks"
              />
            </md-field>
          </v-col>
          <v-col cols="4" xl="4" lg="4">
            <md-field>
              <label for="filter_type">Filter Type</label>
              <md-select
                @input="filteredFeedbacks"
                data-cy="filter-type"
                name="filter-type"
                id="filter-type"
                v-model="filterType"
                placeholder="Filter Type"
                :disabled="loading"
              >
                <md-option class="list_color" value="all">All</md-option>
                <md-option class="list_color" value="name">Name</md-option>
                <md-option class="list_color" value="email">Email</md-option>
                <md-option class="list_color" value="business_name">Business Name</md-option>
                <md-option class="list_color" value="feedback">Feedback</md-option>
              </md-select>
            </md-field>
          </v-col>
          <v-col cols="4" xl="4" lg="4">
                  <v-btn
                    class="ma-2 mt-3 color_class"
                    color="#473068"
                    dark
                    outlined
                    id="clear-filters"
                    :disabled="loading"
                    @click="clearFilters"
                  >
                    Clear filters
                    <v-icon dark right>
                      mdi-cancel
                    </v-icon>
                  </v-btn>
                </v-col>
        </v-row>
      </md-card-content>
    </form>
  </v-row>
  <v-row style="display:flex; justify-content: center;">
    <div style="width: 100%;">
      <div v-if="loading" class="my-5">
          <md-progress-spinner class="color_stroke" md-mode="indeterminate"></md-progress-spinner>
      </div>
      <div v-else-if="!emptyFeedbacks">
        <table v-if="!loading">
          <thead>
              <tr>
                  <th class="user-data" style="width: 20%;">Name</th>
                  <th class="user-data" style="width: 20%;">Email</th>
                  <th class="user-data" style="width: 20%;">Business</th>
                  <th class="user-feedback" style="width: 40%;">FeedBack</th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="feedback in feedbacks" :key="feedback.id">
                <td style="width: 20%;">{{ feedback.user_name }}</td>
                <td style="width: 20%;">{{feedback.email}}</td>
                <td style="width: 20%;">{{feedback.business_name}}</td>
                <td style="width: 40%;">{{ feedback.feedback }}</td>
              </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        {{feedbackError}}
      </div>
    </div>
  </v-row>
</div>
</template>
  
  <script>
  import restAdapter from "../../restAdapter";
  import debounce from "lodash/debounce";
  
  export default {
    name: "ViewFeedback",
    data: () => ({
      feedbackError: "No feedback found",
      emptyFeedbacks: false,
      newFeatures: [],
      feedbacks: null,
      currentPage: 1,
      lastPage: null,
      show: false,
      query: null,
      loading: false,
      filterType: "all"
    }),
    mounted() {
      this.getViewFeedback()
    },
    methods: {
      getViewFeedback() {
        this.loading=true;
        return restAdapter.get("/api/view_feedback").then((response) => {
          this.feedbacks = response.data.allfeedbacks;
          this.emptyFeedbacks = !response.data.allfeedbacks.length;
          this.loading=false;
      });
      },
      debounceSearch: debounce(async function(query, filterType){
        this.loading = true;
       restAdapter.get(`/api/search_feedback/${query}/${filterType}`).then(
          (response)=>{
            if(response.data.filter_feedbacks.length){
              this.feedbacks = response.data.filter_feedbacks;
              this.emptyFeedbacks=false;
              this.loading=false;
            }
            else {
              this.loading=false;
              this.emptyFeedbacks=true;
            }
          }
        );
      },500),

      async filteredFeedbacks(){
          if(this.query){
            await this.debounceSearch(this.query,this.filterType);
          }
          else{
           setTimeout(()=>{ 
            this.getViewFeedback();
           },500);
          }
      },

      clearFilters(){
        this.query="";
        this.filterType="all";

      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .form-container {
    a:not(.md-button):hover {
      text-decoration: none;
    }

    .search-content{
      padding: 50px 130px;
      width: 100vW;
    }
  
  .user-search-bar{
    text-align: right;
    justify-content: flex-end;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
    border-radius: 7px;
  }

  .user-data{
    background-color: #70D0CE;
    color: #473068;
    border: 1px solid #473068;
  }

  .user-feedback{
    background-color: #473068;
    color: #FFFFFF;
  }

    .md-card.md-theme-default:hover {
      .title-style{
        font-weight: 700;
        font-size: 20px;
      }
      box-shadow: 0px 29px 15px rgba(169, 169, 194, 0.19);
    }
  
    .card-style{
      background: #FFFFFF;
      box-shadow: 0px 6px 11px rgba(187, 187, 219, 0.19);
      border-radius: 8px;
    }
    
    .icon-container-purple{
      background-color: #ebdaff;
      border-radius: 8px 8px 0px 0px;
    }
    
    .media-content{
      justify-content: center;
      display: inline-block;
      width: 100px;
      height: 100px;
      margin: 40px;
    }
  
    .title-style{
      color: #473068;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 40px;
    }
  
    .subtitle-style{
      color: #473068;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }

    table{
      width: 82%;
    }    

    table,td {
        margin-left: 10%;
        border: 1px solid #473068;
    }
    table,th {
      height: 50px;
    }

    tfoot {
        color: #fff;
    }

    .color_stroke::v-deep .md-progress-spinner-circle {
      stroke: #492a67 !important;
    }
}
  </style>
   